<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :scroll="{x: true}"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="slot_table_list"
            :is_page="false"
            ref="list"
            rowKey="id"
            :submit_preprocessing="submit_preprocessing"
            @expandedRowsChange="expandedRowsChange($event)"
            @list_after="list_after"
        >
			<template slot="right_btn">
                <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.export/competeReport"></export-table>
            </template>

            <div v-for="(vo,index) in slot_table_list" :key="index" :slot="vo" slot-scope="data">
                <span v-if="vo == 'customer_num'">{{data.text.num + ' | ' + data.text.ratio + '%'}}</span>
                <span v-else>{{data.text.num + ' | ' + data.text.ratio + '%'}}</span>
            </div>
        </TableList>
    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { orderReport, orderReportSub } from "@/api/order";
import { getGoodsTitle,competeReport,competeReportSub } from "@/api/goods";

let columns = [
    {
        title: "名称",
		fixed: 'left',
        dataIndex: "name",
    },
    {
        title: "客户数",
		fixed: 'left',
        dataIndex: "customer_num",
        scopedSlots: {
            customRender: "customer_num"
        }
    },
];

const columns_details = [
    {
        title: '商品',
        dataIndex: 'goods_title'
    },
    {
        title: '价格',
        dataIndex: 'pay_price'
    },
    {
        title: '数量',
        dataIndex: 'num',
        scopedSlots: {
            customRender: "num"
        }
    },
    {
        title: '小计',
        dataIndex: 'sub_price'
    }
]


export default {
    name: "competingrate",
    components: {
        TableList,
    },
    data() {
        return {
            get_table_list: competeReport,
            submit_preprocessing: {
                array_to_string: ['department','role']
            },
            details: {
                visit_data: {

                },
            },
            slot_table_list: ['total'],
            columns,
            columns_details,
            goods_list: [],
            drawer_visible: false,
            form_data_seo: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "tree-select",
                        name: "department",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
					},
                    {
                        type: "tree-select",
                        name: "role",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
					},
					// {
                    //     type: "select",
                    //     name: "role",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "日期",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],

            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
		});

        this.$method.get_role_tree().then(res => {
			this.form_data_seo.list.forEach(item => {
				if (item.name == "role") {
					item.treeData = res;
				}
			});
		});
		

		// this.$method.get_role().then(res => {
        //     this.form_data_seo.list.forEach(item => {
        //         if (item.name == "role") {
        //             item.list = res;
        //         }
        //     });
        // });

        let columns_list = [...this.columns]

        this.$method.get_goods_title({
			data:{
				type:3
			}
		}).then(res => {
            this.goods_list = res.data.list;
            res.data.list.forEach(item => {
                this.slot_table_list.push(item.id)
                columns_list.push(
                    {
                        title: item.goods_title + ' | 铺市率',
                        dataIndex: item.id,
                        width: 200,
                        scopedSlots: {
                            customRender: item.id
                        }
                    }
                )
            })
            this.columns = columns_list;
        })
        
    },
    mounted() {
        
    },
    methods: {
        
        
        get_list_key(list, key, index_arr = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == key) {
                    return list[i]
                }

                if (Array.isArray(list[i].children) && list[i].children.length) {
                    let value = this.get_list_key(list[i].children, key);
                    if (value !== false) {
                        return value;
                    }
                }
            }

            return false;
        },
        expandedRowsChange(e) {
            e.forEach(key => {
                let list = JSON.parse(JSON.stringify(this.$refs.list.list));

                let item = this.get_list_key(list, key);

                if (!item.hasOwnProperty('is_req')) {
                    competeReportSub({
                        data: {
                            id: key,
                            ...this.$refs.list.seo_data
                        }
                    }).then(res => {

                        item.children = [
                            ...res.data.list.map((vo) => {
                                vo.children = [];
                                
                                return vo;
                            })

                        ];
                        item.is_req = true;
                        this.$refs.list.set_data('list', list);
                    })
                }
            });
        },
        list_after(res) {
            let list = this.$refs.list.list;
            list = list.map((item,index) => {
                item.children = [];
                
                return item;
            });

        },
        
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
.visit_img {
    width: 60px;
    height: 60px;
}
</style>